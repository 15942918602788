import React, { useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';
import Layout from "../../components/layout";
import Seo from "../../components/seo";

//sections
import BrainingCampHeroSection from "../sections/caseStudies/brainingCamp/BrainingCampHeroSection";
import ChallengesSection from "../sections/caseStudies/ChallengesSection";
import BrainingCampSection1 from "../sections/caseStudies/brainingCamp/BrainingCampSection1";
import BrainingCampSection2 from '../sections/caseStudies/brainingCamp/BrainingCampSection2';
import BrainingCampSection3 from '../sections/caseStudies/brainingCamp/BrainingCampSection3';
import BrainingCampSection4 from '../sections/caseStudies/brainingCamp/BrainingCampSection4';
import BrainingCampSection5 from '../sections/caseStudies/brainingCamp/BrainingCampSection5';
import BrainingCampSection6 from '../sections/caseStudies/brainingCamp/BrainingCampSection6';
import BrainingCampSection7 from '../sections/caseStudies/brainingCamp/BrainingCampSection7';

const BrainingCamp = ({ data }) => {
  const mainData = data.allWpPage.nodes[0].caseStudyBrainingcampACF;
  console.log(mainData);
  const sectionRef = useRef();
  const [bgTransition, setBgTransition] = useState('');
  // useEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger)
  //   setTimeout(() => {
  //     gsap.set(sectionRef.current, {
  //       scrollTrigger: {
  //         trigger: '.bariningcamp-case-study .section-2',
  //         start: "top 60%",
  //         end: "bottom 60%",
  //         ease: "ease",
  //         scrub: true,
  //         markers: false,
  //         onEnter: () => setBgTransition('brainingcamp-bg'),
  //         onLeave: () => setBgTransition(''),
  //         onEnterBack: () => setBgTransition('brainingcamp-bg'),
  //         onLeaveBack: () => setBgTransition(''),
  //       }
  //     });
  //   }, 1000);
  // }, []);
  return (
    <Layout>
      <Seo title="BrainningCamp" />
      <div className={"bariningcamp-case-study page-wrapper " + bgTransition} id="brainingcamp-casestudy-page-wrapper" ref={sectionRef}>
        <BrainingCampHeroSection
          preTitle={parse(mainData.brainingcampHeroSectionPretitle)}
          mainTitle={parse(mainData.brainingcampHeroSectionTitle)}
          image1x={
            {
              sourceUrl: mainData.brainingcampHeroSectionImage1x.sourceUrl,
              altText: mainData.brainingcampHeroSectionImage1x.altText,
              width: mainData.brainingcampHeroSectionImage1x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.brainingcampHeroSectionImage2x.sourceUrl,
              altText: mainData.brainingcampHeroSectionImage2x.altText,
              width: mainData.brainingcampHeroSectionImage2x.width,
            }
          }
        />
        <ChallengesSection
          companyInfoList={mainData.brainingcampChallengeSectionCompanyInfoList}
          challengesList={mainData.brainingcampChallengeSectionChallangesList}
        />
        <BrainingCampSection1
          image11x={
            {
              sourceUrl: mainData.brainingcampSection1Image11x.sourceUrl,
              altText: mainData.brainingcampSection1Image11x.altText,
              width: mainData.brainingcampSection1Image11x.width,
            }
          }
          image12x={
            {
              sourceUrl: mainData.brainingcampSection1Image12x.sourceUrl,
              altText: mainData.brainingcampSection1Image12x.altText,
              width: mainData.brainingcampSection1Image12x.width,
            }
          }
          image21x={
            {
              sourceUrl: mainData.brainingcampSection1Image21x.sourceUrl,
              altText: mainData.brainingcampSection1Image21x.altText,
              width: mainData.brainingcampSection1Image21x.width,
            }
          }
          image22x={
            {
              sourceUrl: mainData.brainingcampSection1Image22x.sourceUrl,
              altText: mainData.brainingcampSection1Image22x.altText,
              width: mainData.brainingcampSection1Image22x.width,
            }
          }
          image31x={
            {
              sourceUrl: mainData.brainingcampSection1Image31x.sourceUrl,
              altText: mainData.brainingcampSection1Image31x.altText,
              width: mainData.brainingcampSection1Image31x.width,
            }
          }
          image32x={
            {
              sourceUrl: mainData.brainingcampSection1Image32x.sourceUrl,
              altText: mainData.brainingcampSection1Image32x.altText,
              width: mainData.brainingcampSection1Image32x.width,
            }
          }
        />
        <BrainingCampSection2 
          mainTitle={parse(mainData.brainingcampSection2Tilte)}
          imageslist={mainData.brainingcampSection2ImageList}
        />
        <BrainingCampSection3 
          mainTitle={parse(mainData.brainingcampSection3Tilte)}
          mainDescription={parse(mainData.brainingcampSection3Description)}
          image1x={
            {
              sourceUrl: mainData.brainingcampSection3Image1x.sourceUrl,
              altText: mainData.brainingcampSection3Image1x.altText,
              width: mainData.brainingcampSection3Image1x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.brainingcampSection3Image2x.sourceUrl,
              altText: mainData.brainingcampSection3Image2x.altText,
              width: mainData.brainingcampSection3Image2x.width,
            }
          }
        />
        <BrainingCampSection4 
          mainTitle={parse(mainData.brainingcampSection4Tilte)}
          mainDescription={parse(mainData.brainingcampSection4Description)}
          imageslist={mainData.brainingcampSection4ImageList}
          mainTitle2={parse(mainData.brainingcampSection4Tilte2)}
          mainDescription2={parse(mainData.brainingcampSection4Description2)}
          imageslist2={mainData.brainingcampSection4ImageList2}
        />
        <BrainingCampSection5 
          mainTitle={parse(mainData.brainingcampSection5Tilte)}
          image11x={
            {
              sourceUrl: mainData.brainingcampSection5Image11x.sourceUrl,
              altText: mainData.brainingcampSection5Image11x.altText,
              width: mainData.brainingcampSection5Image11x.width,
            }
          }
          image12x={
            {
              sourceUrl: mainData.brainingcampSection5Image12x.sourceUrl,
              altText: mainData.brainingcampSection5Image12x.altText,
              width: mainData.brainingcampSection5Image12x.width,
            }
          }
          image21x={
            {
              sourceUrl: mainData.brainingcampSection5Image21x.sourceUrl,
              altText: mainData.brainingcampSection5Image21x.altText,
              width: mainData.brainingcampSection5Image21x.width,
            }
          }
          image22x={
            {
              sourceUrl: mainData.brainingcampSection5Image22x.sourceUrl,
              altText: mainData.brainingcampSection5Image22x.altText,
              width: mainData.brainingcampSection5Image22x.width,
            }
          }
          imageslist={mainData.brainingcampSection5ImageList}
        />
        <BrainingCampSection6 
          mainTitle={parse(mainData.brainingcampSection6Tilte)}
          imageslist={mainData.brainingcampSection6ImageList}
        />
        <BrainingCampSection7 
          image1x={
            {
              sourceUrl: mainData.brainingcampSection7Image1x.sourceUrl,
              altText: mainData.brainingcampSection7Image1x.altText,
              width: mainData.brainingcampSection7Image1x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.brainingcampSection7Image2x.sourceUrl,
              altText: mainData.brainingcampSection7Image2x.altText,
              width: mainData.brainingcampSection7Image2x.width,
            }
          }
        />
      </div>
    </Layout>
  )
}



export const BrainingcampQuery = graphql`

{
  allWpPage(filter: {slug: {eq: "brainingcamp"}}) {
      nodes {
        caseStudyBrainingcampACF {
          brainingcampHeroSectionImage1x {
            altText
            sourceUrl
            width
          }
          brainingcampHeroSectionImage2x {
            altText
            sourceUrl
            width
          }
          brainingcampHeroSectionPretitle
          brainingcampHeroSectionTitle
          brainingcampChallengeSectionChallangesList {
            title
            description
          }
          brainingcampChallengeSectionCompanyInfoList {
            title
            description
          }
          brainingcampSection1Image11x {
            altText
            sourceUrl
            width
          }
          brainingcampSection1Image12x {
            altText
            sourceUrl
            width
          }
          brainingcampSection1Image21x {
            altText
            sourceUrl
            width
          }
          brainingcampSection1Image22x {
            altText
            sourceUrl
            width
          }
          brainingcampSection1Image31x {
            altText
            sourceUrl
            width
          }
          brainingcampSection1Image32x {
            altText
            sourceUrl
            width
          }
          brainingcampSection2Tilte
          brainingcampSection2ImageList {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }
          brainingcampSection3Tilte
          brainingcampSection3Description
          brainingcampSection3Image1x {
            altText
            sourceUrl
            width
          }
          brainingcampSection3Image2x {
            altText
            sourceUrl
            width
          }
          brainingcampSection4Tilte
          brainingcampSection4Description
          brainingcampSection4ImageList {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }
          brainingcampSection4Tilte2
          brainingcampSection4Description2
          brainingcampSection4ImageList2 {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }

          brainingcampSection5Tilte
          brainingcampSection5Image11x {
            altText
            sourceUrl
            width
          }
          brainingcampSection5Image12x {
            altText
            sourceUrl
            width
          }
          brainingcampSection5Image21x {
            altText
            sourceUrl
            width
          }
          brainingcampSection5Image22x {
            altText
            sourceUrl
            width
          }
          brainingcampSection5ImageList {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }

          brainingcampSection6Tilte
          brainingcampSection6ImageList {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }
          brainingcampSection7Image2x {
            altText
            sourceUrl
            width
          }
          brainingcampSection7Image1x {
            altText
            sourceUrl
            width
          }
        }
      }
    }
}

`

export default BrainingCamp